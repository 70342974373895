import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'; // Added useRef
import { ThemeProvider, createGlobalStyle, styled } from 'styled-components';
import useTheme from './hooks/useTheme';
import useGlobalSearchFocus from './hooks/useGlobalSearchFocus'; // Import the new hook
import { fontFamilies, fontSizes, fontWeights, lineHeights, letterSpacings } from './styles/typography';
import { space, sizes, shadows, radii, zIndices } from './styles/tokens';
import { breakpoints, mediaQueries } from './styles/breakpoints';
import { siteConfig } from './config/sites'; // Import the single config object

// Components
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import SearchInput from './components/Form/SearchInput';
import SearchSuggestions from './components/SearchSuggestions/SearchSuggestions';

// Helper function to extract domain from URL (copied from NavMenu for now)
const getDomainFromUrl = (url) => {
  try {
    // Ensure protocol for URL constructor if missing (assume https)
    const fullUrl = url.includes('://') ? url : `https://${url}`;
    const parsedUrl = new URL(fullUrl);
    // Remove 'www.' if present
    return parsedUrl.hostname.replace(/^www\./, '');
  } catch (e) {
    // Handle cases like "localhost" or invalid URLs gracefully
    // If it doesn't parse but looks like a domain pattern, return it?
    // Or return null and let the favicon hook handle it. Let's return null.
    // console.warn(`Invalid URL for domain extraction: ${url}`);
    return null; // Return null if URL is invalid or not parsable
  }
};

// Helper function to extract RGB values from hex color
const hexToRgb = (hex) => {
  const cleanHex = hex.replace('#', '');
  const r = parseInt(cleanHex.substring(0, 2), 16);
  const g = parseInt(cleanHex.substring(2, 4), 16);
  const b = parseInt(cleanHex.substring(4, 6), 16);
  return `${r}, ${g}, ${b}`;
};

// Global styles
const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: ${({ theme }) => theme.colors.primary};
    --secondary-color: ${({ theme }) => theme.colors.secondary};
    --accent-color: ${({ theme }) => theme.colors.accent};
    --success-color: ${({ theme }) => theme.colors.success};
    --warning-color: ${({ theme }) => theme.colors.warning};
    --error-color: ${({ theme }) => theme.colors.error};
    --info-color: ${({ theme }) => theme.colors.info};
    
    --primary-hover: ${({ theme }) => theme.colors.primaryHover};
    --secondary-hover: ${({ theme }) => theme.colors.secondaryHover};
    --primary-active: ${({ theme }) => theme.colors.primaryActive};
    --secondary-active: ${({ theme }) => theme.colors.secondaryActive};
    
    /* Extract RGB values for use with alpha */
    --primary-color-rgb: ${({ theme }) => hexToRgb(theme.colors.primary)};
    --secondary-color-rgb: ${({ theme }) => hexToRgb(theme.colors.secondary)};
    --accent-color-rgb: ${({ theme }) => hexToRgb(theme.colors.accent)};
    --success-color-rgb: ${({ theme }) => hexToRgb(theme.colors.success)};
    --warning-color-rgb: ${({ theme }) => hexToRgb(theme.colors.warning)};
    --error-color-rgb: ${({ theme }) => hexToRgb(theme.colors.error)};
    
    /* Animation durations */
    --animation-fast: 150ms;
    --animation-normal: 300ms;
    --animation-slow: 500ms;
  }

  html {
    height: 100%;
    font-size: 16px;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.fontFamilies.mono};
    background-color: var(--primary-color);
    color: var(--secondary-color);
    overflow: hidden;
    overscroll-behavior: none;
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
    transition: background-color var(--animation-normal) ease, 
                color var(--animation-normal) ease;
    letter-spacing: 0.01em;
  }

  #root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  /* Improved focus styles for accessibility */
  :focus-visible {
    outline: 2px solid var(--accent-color);
    outline-offset: 2px;
  }

  /* Remove tap highlight on mobile */
  input,
  textarea,
  button,
  select,
  a,
  div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  /* Smooth scrolling */
  html {
    scroll-behavior: smooth;
  }
  
  /* Fix 100vh issue on mobile browsers */
  @supports (-webkit-touch-callout: none) {
    .vh-fix {
      height: -webkit-fill-available;
    }
  }
  
  /* Improved button styling */
  button {
    cursor: pointer;
    font-family: inherit;
    border: none;
    background: none;
    transition: all var(--animation-fast) ease;
  }
`;

// Main content area - now centers the search input
const MainContent = styled.main`
  flex-grow: 1; /* Allow main content to fill available space */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 56px; /* Space for fixed Header (match Header height) */
  padding-bottom: 40px; /* Space for fixed Footer (match Footer height) */
  overflow-y: auto; /* Allow scrolling if content overflows vertically */

  /* Adjust padding based on media queries if Header/Footer height changes */
  ${({ theme }) => theme.mediaQueries.xs} {
    padding-top: 50px;
    padding-bottom: 35px;
  }
`;

// URL detection regex (defined outside component for stability)
const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/.+)?$/i;

function App() {
  // siteConfig is now directly imported
  const { selectedPalette, applyColorPalette, getThemeColors } = useTheme();
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // Lifted state
  const [suggestions, setSuggestions] = useState([]); // Site suggestions state
  const [detectedUrl, setDetectedUrl] = useState(null); // State for detected URL
  const [detectedDomain, setDetectedDomain] = useState(null); // State for extracted domain
  const [defaultSearchSuggestion, setDefaultSearchSuggestion] = useState(null); // State for the default search suggestion
  const [showSuggestions, setShowSuggestions] = useState(false); // Visibility state
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // Keyboard nav state
  const searchInputRef = useRef(null); // Ref for positioning suggestions

  // Hook to handle global keyboard listener for search focus
  useGlobalSearchFocus();

  // Function to toggle NavMenu visibility
  const toggleNavMenu = useCallback(() => {
    setIsNavMenuOpen(prev => !prev);
  }, []);

  // Set document title based on site config
  useEffect(() => {
    document.title = siteConfig.title;
  }, []);

  // Function to handle actual search/navigation
  const executeSearch = useCallback((term) => {
    if (!term) return;

    let targetUrl = '';
    if (urlRegex.test(term)) {
      targetUrl = term.startsWith('http://') || term.startsWith('https://') ? term : `https://${term}`;
    } else {
      const lowerTerm = term.toLowerCase();
      const matchedLink = siteConfig.navLinks.find(link =>
        link.label.toLowerCase() === lowerTerm ||
        (term.length >= 3 && link.label.toLowerCase().includes(lowerTerm))
      );
      if (matchedLink) {
        targetUrl = matchedLink.url;
      } else {
        targetUrl = `https://search.ffive.org/search?q=${encodeURIComponent(term)}`;
      }
    }

    if (targetUrl) {
      window.open(targetUrl, '_blank', 'noopener,noreferrer');
    }

    setSearchTerm(''); // Clear input after search
    setShowSuggestions(false); // Hide suggestions
    setIsNavMenuOpen(false); // Close nav menu if open
    setSearchTerm(''); // Clear input after execution
    setShowSuggestions(false); // Hide suggestions
    setHighlightedIndex(-1); // Reset highlight
  }, []);

  // Find the SEARCH link config once
  const searchLinkConfig = useMemo(() =>
    siteConfig.navLinks.find(link => link.label === 'SEARCH'),
    [] // Depends only on static config
  );

  // Update suggestions, detect URL, and set default search based on searchTerm
  useEffect(() => {
    const trimmedSearchTerm = searchTerm.trim();

    if (trimmedSearchTerm === '') {
      setSuggestions([]);
      setDetectedUrl(null);
      setDetectedDomain(null);
      setDefaultSearchSuggestion(null);
      setShowSuggestions(false);
      setHighlightedIndex(-1);
      return;
    }

    const lowerSearchTerm = trimmedSearchTerm.toLowerCase();
    let isUrl = urlRegex.test(trimmedSearchTerm);
    let isSiteMatch = false;
    let currentDetectedUrl = null;
    let currentDetectedDomain = null;
    let currentDefaultSearch = null;

    // 1. Check for URL
    if (isUrl) {
      currentDetectedUrl = trimmedSearchTerm;
      currentDetectedDomain = getDomainFromUrl(trimmedSearchTerm);
      // Keep URL suggestion even if domain extraction fails
    }

    // 2. Check for Site Matches (only if not a URL)
    const filteredSiteSuggestions = siteConfig.navLinks.filter(link =>
      link.label.toLowerCase().includes(lowerSearchTerm)
    );
    if (!isUrl && filteredSiteSuggestions.length > 0) {
      isSiteMatch = true;
    }

    // 3. Determine if Default Search is needed
    // Show default search if the term is not empty, not a URL, and not a site match
    if (trimmedSearchTerm && !isUrl && !isSiteMatch && searchLinkConfig) {
      const encodedQueryString = `?q=${encodeURIComponent(trimmedSearchTerm)}`;
      const searchUrl = `${searchLinkConfig.url}/search${encodedQueryString}`;
      // Use the raw search term itself as the label
      currentDefaultSearch = {
        type: 'search',
        label: trimmedSearchTerm, // Display just the search term
        url: searchUrl,           // URL to execute remains the full, encoded URL
        iconDomain: getDomainFromUrl(searchLinkConfig.url), // Domain for favicon
      };
    }

    setDetectedUrl(currentDetectedUrl);
    setDetectedDomain(currentDetectedDomain);
    setSuggestions(filteredSiteSuggestions);
    setDefaultSearchSuggestion(currentDefaultSearch);

    // Show suggestions if there's a detected URL OR site suggestions OR a default search suggestion
    setShowSuggestions(currentDetectedUrl !== null || filteredSiteSuggestions.length > 0 || currentDefaultSearch !== null);
    setHighlightedIndex(-1); // Reset highlight on new suggestions

  }, [searchTerm, searchLinkConfig]); // Rerun when searchTerm or config changes

  // Combine all potential suggestions for rendering and keyboard nav
  const combinedSuggestions = useMemo(() => {
    let combined = [];
    if (detectedUrl) {
      // Ensure URL starts with https:// for execution consistency
      const displayUrl = detectedUrl.startsWith('http') ? detectedUrl : `https://${detectedUrl}`;
      combined.push({
        type: 'url',
        label: detectedDomain, // Use pretty domain as label for display/internal consistency
        url: displayUrl,       // Use full URL for execution
        domain: detectedDomain, // Pass domain for favicon hook
      });
    }
    combined = combined.concat(suggestions); // Add site suggestions
    if (defaultSearchSuggestion) {
      combined.push(defaultSearchSuggestion); // Add default search suggestion
    }
    return combined;
  }, [detectedUrl, detectedDomain, suggestions, defaultSearchSuggestion]);

  // Handle selecting any suggestion type
  const handleSelectSuggestion = useCallback((suggestion) => {
    if (!suggestion) return;
    // URL and Search types have 'url', Site type needs lookup via label
    if (suggestion.type === 'url' || suggestion.type === 'search') {
      executeSearch(suggestion.url); // Execute directly using the URL
    } else {
      executeSearch(suggestion.label); // Use label for site links (executeSearch handles lookup)
    }
  }, [executeSearch]);

  // Handle submitting the search form (Enter key)
  const handleSearchSubmit = useCallback((submittedTerm) => {
    if (highlightedIndex >= 0 && combinedSuggestions[highlightedIndex]) {
      // If a suggestion is highlighted, execute that suggestion
      handleSelectSuggestion(combinedSuggestions[highlightedIndex]);
    } else if (submittedTerm) {
      // Otherwise, execute based on the raw input term (URL, site, or default search)
      executeSearch(submittedTerm);
    }
    // Clearing and hiding is now handled within executeSearch
  }, [executeSearch, highlightedIndex, combinedSuggestions, handleSelectSuggestion]);

  // Handle keyboard navigation for suggestions
  const handleKeyDown = useCallback((e) => {
    const totalSuggestions = combinedSuggestions.length;
    if (!showSuggestions || totalSuggestions === 0) return;

    let newIndex = highlightedIndex;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        newIndex = (highlightedIndex + 1) % totalSuggestions;
        setHighlightedIndex(newIndex);
        break;
      case 'ArrowUp':
        e.preventDefault();
        newIndex = (highlightedIndex - 1 + totalSuggestions) % totalSuggestions;
        setHighlightedIndex(newIndex);
        break;
      case 'Enter':
        // Let handleSearchSubmit handle Enter logic
        // It checks highlightedIndex already
        break; // Do nothing here, submit handler takes over
      case 'Escape':
        e.preventDefault();
        setShowSuggestions(false);
        setHighlightedIndex(-1);
        break;
      default:
        // Reset highlight if user types more characters
        setHighlightedIndex(-1);
        break;
    }
  }, [showSuggestions, combinedSuggestions, highlightedIndex]);

  // Handle input focus (show if term exists and there are any suggestions)
  const handleFocus = useCallback(() => {
    if (searchTerm.trim() !== '' && combinedSuggestions.length > 0) {
      setShowSuggestions(true);
    }
  }, [searchTerm, combinedSuggestions]);

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
        setShowSuggestions(false);
        setHighlightedIndex(-1);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Get current theme colors
  const colors = getThemeColors();

  // Memoize the theme object to prevent unnecessary re-renders
  const theme = useMemo(() => {
    // Calculate hover and active states only when colors change
    const primaryHover = colors.primary + '11';
    const secondaryHover = colors.secondary + '11';
    const primaryActive = colors.primary + '22';
    const secondaryActive = colors.secondary + '22';

    return {
      colors: {
        ...colors,
        primaryHover,
        secondaryHover,
        primaryActive,
        secondaryActive,
      },
      fontFamilies,
      fontSizes,
      fontWeights,
      lineHeights,
      letterSpacings,
      space,
      sizes,
      shadows,
      radii,
      zIndices,
      mediaQueries,
      breakpoints,
    };
  }, [colors]); // Dependency: only recalculate theme when colors object changes

  // Keyboard listener logic is now handled by useGlobalSearchFocus hook

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {/* Render the new Header */}
      <Header
        links={siteConfig.navLinks}
        selectedPalette={selectedPalette}
        onSelectPalette={applyColorPalette}
        isNavMenuOpen={isNavMenuOpen}
        toggleNavMenu={toggleNavMenu}
        // setIframeSrc prop will be removed from NavMenu later
        setIframeSrc={() => {}} // Pass dummy function for now
      />
      {/* Main content area centers search and suggestions */}
      <MainContent>
        <SearchInput
          ref={searchInputRef} // Pass the ref
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onSearch={handleSearchSubmit} // Use the new submit handler
          onKeyDown={handleKeyDown} // Pass keydown handler
          onFocus={handleFocus} // Pass focus handler
          aria-haspopup="listbox" // Accessibility
          aria-expanded={showSuggestions}
          aria-controls="search-suggestions-list"
          aria-activedescendant={highlightedIndex >= 0 ? `suggestion-item-${highlightedIndex}` : undefined}
        />
        <SearchSuggestions
          // Pass all suggestion types down
          siteSuggestions={suggestions} // Keep only one instance
          detectedUrl={detectedUrl}
          detectedDomain={detectedDomain}
          defaultSearchSuggestion={defaultSearchSuggestion} // Pass new prop
          showSuggestions={showSuggestions}
          onSelectSuggestion={handleSelectSuggestion}
          highlightedIndex={highlightedIndex}
          triggerRef={searchInputRef}
          listId="search-suggestions-list" // Accessibility
          itemPrefixId="suggestion-item" // Accessibility
        />
      </MainContent>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
