import React, { useRef } from 'react'; // Import useRef
import styled from 'styled-components';

// Import child components
import NavMenu from './NavMenu'; // Assuming NavMenu will be triggered here
import ColorPalette from '../ThemeSelector/ColorPalette';
import Logo from '../Typography/Logo'; // Assuming a Logo component exists

const HeaderBar = styled.header`
  height: 56px; /* Adjust height as needed */
  background-color: var(--primary-color);
  border-bottom: 1px solid rgba(var(--secondary-color-rgb), 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space out left and right widgets */
  padding: 0 ${({ theme }) => theme.space[3]};
  z-index: ${({ theme }) => theme.zIndices.sticky};
  flex-shrink: 0;
  position: fixed; /* Fixed position at the top */
  top: 0;
  left: 0;
  right: 0;
  transition: background-color var(--animation-normal) ease;

  /* Mobile adjustments if needed */
  ${({ theme }) => theme.mediaQueries.xs} {
    height: 50px;
    padding: 0 ${({ theme }) => theme.space[2]};
  }
`;

// Widget system adapted for Header
const HeaderWidget = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
`;

const LeftWidget = styled(HeaderWidget)`
  gap: ${({ theme }) => theme.space[2]}; /* Gap between logo and potential future items */
`;

const RightWidget = styled(HeaderWidget)`
  gap: ${({ theme }) => theme.space[2]}; /* Gap between items like ColorPalette */
`;

// Placeholder for Logo/NavMenu trigger interaction
const LogoContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
`;

const Header = ({
  links, // Pass links for NavMenu
  selectedPalette,
  onSelectPalette,
  isNavMenuOpen,
  toggleNavMenu,
  // Removed setIframeSrc prop
}) => {
  const logoTriggerRef = useRef(null); // Create a ref for the trigger

  return (
    <HeaderBar>
      <LeftWidget>
        {/* Logo will trigger NavMenu */}
        <LogoContainer ref={logoTriggerRef} onClick={toggleNavMenu}> {/* Attach ref */}
          <Logo /> {/* Removed title prop */}
        </LogoContainer>
        {/* NavMenu receives the triggerRef */}
        <NavMenu
          links={links}
          isNavMenuOpen={isNavMenuOpen}
          toggleNavMenu={toggleNavMenu}
          triggerRef={logoTriggerRef} // Pass the ref down
        />
      </LeftWidget>

      <RightWidget>
        <ColorPalette
          selectedPalette={selectedPalette}
          onSelectPalette={onSelectPalette}
        />
        {/* Add other right-side widgets here if needed */}
      </RightWidget>
    </HeaderBar>
  );
};

export default Header;
