import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colorPalettes } from '../../styles/theme';

// Subtle animation for random palette preview
const rainbow = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => theme.space[3]};
  border-radius: ${({ theme }) => theme.radii.md};
  transition: all var(--animation-normal) ease;
  background-color: ${({ $isSelected }) => 
    $isSelected ? 'rgba(var(--primary-color-rgb), 0.1)' : 'transparent'};
  color: var(--primary-color);
  position: relative;
  
  /* Larger touch target */
  min-height: 44px;
  
  /* Hover effect */
  &:hover {
    background-color: rgba(var(--primary-color-rgb), 0.05);
  }
  
  /* Active feedback */
  &:active {
    transform: scale(0.99);
  }
  
  /* Selected indicator */
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: ${({ $isSelected }) => 
      $isSelected ? 'var(--accent-color)' : 'transparent'};
    transition: all var(--animation-normal) ease;
  }
`;

const PalettePreview = styled.div`
  display: flex;
  width: 50px;
  height: 24px;
  border-radius: ${({ theme }) => theme.radii.md};
  overflow: hidden;
  margin-right: ${({ theme }) => theme.space[3]};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all var(--animation-normal) ease;
  
  /* Mobile optimization - slightly larger on small screens */
  ${({ theme }) => theme.mediaQueries.xs} {
    width: 60px;
    height: 28px;
  }
`;

const PreviewLeft = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${({ color }) => color};
`;

const PreviewRight = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${({ color }) => color};
`;

const RandomPreviewLeft = styled(PreviewLeft)`
  background: linear-gradient(270deg, #ff0000, #ff8000, #ffff00, #00ff00, #00ffff, #0000ff, #8000ff, #ff00ff);
  background-size: 1600% 1600%;
  animation: ${rainbow} 20s ease infinite;
`;

const RandomPreviewRight = styled(PreviewRight)`
  background: linear-gradient(270deg, #ffffff, #f0f0f0, #e0e0e0, #d0d0d0, #e0e0e0, #f0f0f0, #ffffff);
  background-size: 800% 800%;
  animation: ${rainbow} 20s ease infinite reverse;
`;

const PaletteName = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: inherit;
  text-transform: capitalize;
  font-weight: ${({ $isSelected, theme }) => 
    $isSelected ? theme.fontWeights.bold : theme.fontWeights.normal};
`;

const CheckMark = styled.span`
  margin-left: auto;
  color: var(--accent-color);
  opacity: ${({ $isSelected }) => $isSelected ? 0.8 : 0};
  transition: opacity var(--animation-normal) ease;
`;

const PaletteOption = ({ paletteName, isSelected, onClick }) => {
  // Format palette name for display
  const displayName = paletteName.charAt(0).toUpperCase() + paletteName.slice(1);
  
  // Handle random palette preview
  if (paletteName === 'random') {
    return (
      <OptionContainer 
        onClick={onClick} 
        $isSelected={isSelected}
        role="button"
        aria-pressed={isSelected}
      >
        <PalettePreview>
          <RandomPreviewLeft />
          <RandomPreviewRight />
        </PalettePreview>
        <PaletteName $isSelected={isSelected}>{displayName}</PaletteName>
        <CheckMark $isSelected={isSelected}>✓</CheckMark>
      </OptionContainer>
    );
  }
  
  // Get colors from palette
  const palette = colorPalettes[paletteName] || colorPalettes.black;
  
  return (
    <OptionContainer 
      onClick={onClick} 
      $isSelected={isSelected}
      role="button"
      aria-pressed={isSelected}
    >
      <PalettePreview>
        <PreviewLeft color={palette.primary} />
        <PreviewRight color={palette.secondary} />
      </PalettePreview>
      <PaletteName $isSelected={isSelected}>{displayName}</PaletteName>
      <CheckMark $isSelected={isSelected}>✓</CheckMark>
    </OptionContainer>
  );
};

export default PaletteOption;
