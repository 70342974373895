import React from 'react';
import styled from 'styled-components';
import FloatingPopup from './FloatingPopup';
import useFavicon from '../../hooks/useFavicon'; // Import the hook

// Helper to extract domain from URL
const getDomainFromUrl = (url) => {
  try {
    const parsedUrl = new URL(url);
    // Remove 'www.' if present
    return parsedUrl.hostname.replace(/^www\./, '');
  } catch (e) {
    console.warn(`Invalid URL for domain extraction: ${url}`);
    return null; // Return null if URL is invalid
  }
};

// Container for the menu content itself (used within FloatingPopup)
const MenuContentContainer = styled.div`
  padding: ${({ theme }) => theme.space[2]}; /* Add some padding around the items */
`;

// Modern styled menu items (styles remain largely the same)
const NavLinkItem = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => theme.space[3]};
  border-radius: ${({ theme }) => theme.radii.md};
  transition: all var(--animation-fast) ease;
  color: var(--primary-color); /* Text color inside the popup */
  background-color: transparent;
  border: none;
  text-align: left;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamilies.mono};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin-bottom: ${({ theme }) => theme.space[1]}; /* Add small gap between items */

  &:hover {
    background-color: rgba(var(--primary-color-rgb), 0.1);
    transform: translateX(4px);
  }

  &:active {
    transform: translateX(4px) scale(0.98);
  }
  
  &:last-child {
    margin-bottom: 0;
  }

  /* Style for the favicon image */
  img {
    width: 16px; /* Standard favicon size */
    height: 16px;
    margin-right: ${({ theme }) => theme.space[3]}; /* Space between icon and text */
    object-fit: contain; /* Ensure icon scales nicely */
    flex-shrink: 0; /* Prevent icon from shrinking */
  }
`;

// Separator for menu sections (keep as is)
const MenuSeparator = styled.div`
  height: 1px;
  background-color: rgba(var(--primary-color-rgb), 0.15);
  margin: ${({ theme }) => theme.space[3]} 0;
  width: 100%;
`;

// Styled span for the lock icon to give it similar spacing as the img
const LockIconSpan = styled.span`
  display: inline-block; /* Or inline-flex */
  width: 16px; /* Match image width */
  height: 16px; /* Match image height */
  margin-right: ${({ theme }) => theme.space[3]}; /* Match image margin */
  text-align: center; /* Center the lock icon */
  line-height: 16px; /* Vertically center */
  flex-shrink: 0;
  color: var(--accent-color); /* Use accent color for lock */
`;

// NavMenu component using the hook
const NavMenu = ({ links, isNavMenuOpen, toggleNavMenu, triggerRef }) => {

  // Simplified click handler: always open in new tab and close menu
  const handleLinkClick = (link) => {
    window.open(link.url, '_blank', 'noopener,noreferrer');
    toggleNavMenu(); // Close menu after clicking any link
  };

  // Group links by type (auth vs others)
  const normalLinks = links.filter(link => link.navType !== 'auth');
  const authLinks = links.filter(link => link.navType === 'auth');

  // Render a link item: lock icon for auth, favicon otherwise
  const RenderLinkItem = ({ link }) => {
    // Only get domain and call hook if not an auth link
    const isAuthLink = link.navType === 'auth';
    const domain = !isAuthLink ? getDomainFromUrl(link.url) : null;
    const iconUrl = useFavicon(domain); // Hook handles null domain gracefully

    return (
      <NavLinkItem onClick={() => handleLinkClick(link)}>
        {isAuthLink ? (
          <LockIconSpan aria-hidden="true">🔒</LockIconSpan> // Render lock icon for auth
        ) : (
          <img // Render favicon for non-auth
            src={iconUrl}
            alt="" // Decorative, alt text provided by link label
            onError={(e) => {
              // Fallback if image fails to load
              if (e.target.src !== '/assets/icons/favicon.ico') {
                e.target.onerror = null;
                e.target.src = '/assets/icons/favicon.ico';
              }
            }}
          />
        )}
        {link.label}
      </NavLinkItem>
    );
  };

  // The component now only renders the FloatingPopup, assuming the trigger exists elsewhere (in Header)
  // Main render logic
  return (
    <FloatingPopup
      isOpen={isNavMenuOpen}
      onClose={toggleNavMenu}
      triggerRef={triggerRef} // Use the ref passed from Header
      alignment="left" // Keep alignment
    >
      <MenuContentContainer>
        {/* Render non-auth links */}
        {normalLinks.map((link, index) => <RenderLinkItem key={`normal-${index}`} link={link} />)}

        {/* Separator and Auth apps (only if there are any auth links) */}
        {authLinks.length > 0 && (
          <>
            <MenuSeparator />
            {authLinks.map((link, index) => <RenderLinkItem key={`auth-${index}`} link={link} />)}
          </>
        )}
      </MenuContentContainer>
    </FloatingPopup>
  );
};

export default NavMenu;
