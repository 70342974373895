import { useState, useEffect } from 'react';

const CACHE_PREFIX = 'favicon_cache_';
const CACHE_EXPIRATION_MS = 7 * 24 * 60 * 60 * 1000; // 7 days
const FALLBACK_ICON_URL = '/assets/icons/favicon.ico'; // Assumes PUBLIC_URL resolution
const DDG_FAVICON_SERVICE_URL = 'https://icons.duckduckgo.com/ip3/';

/**
 * Provides a URL for a domain's favicon, using DuckDuckGo's service
 * and localStorage for caching. Relies on the <img> tag's onError
 * handler for actual fallback if the DDG URL fails.
 *
 * @param {string | null | undefined} domain The domain to get the favicon URL for (e.g., 'google.com').
 * @returns {string} The potential URL of the favicon (DDG, cached, or initial fallback).
 */
const useFavicon = (domain) => {
  // Initialize with fallback, but expect it to be quickly replaced
  // unless the domain is invalid/null from the start.
  const [iconUrl, setIconUrl] = useState(FALLBACK_ICON_URL);

  useEffect(() => {
    if (!domain) {
      setIconUrl(FALLBACK_ICON_URL); // Set fallback if domain is invalid/cleared
      return;
    }

    const cacheKey = `${CACHE_PREFIX}${domain}`;
    let cachedUrl = null;
    // Check cache first
    const cachedItem = localStorage.getItem(cacheKey);
    if (cachedItem) {
      try {
        const parsedData = JSON.parse(cachedItem);
        // Check expiration
        if (parsedData.timestamp && Date.now() - parsedData.timestamp <= CACHE_EXPIRATION_MS) {
          cachedUrl = parsedData.url; // Use cached URL (could be DDG or previous fallback)
        } else {
          localStorage.removeItem(cacheKey); // Cache expired
        }
      } catch (e) {
        console.error('Error parsing favicon cache:', e);
        localStorage.removeItem(cacheKey); // Clear corrupted cache
      }
    }

    if (cachedUrl) {
      // If valid cache found, use it and we're done for this render
      if (iconUrl !== cachedUrl) { // Avoid unnecessary state update if already correct
         setIconUrl(cachedUrl);
      }
    } else {
      // If not cached or expired, construct the DDG URL
      const ddgUrl = `${DDG_FAVICON_SERVICE_URL}${domain}.ico`;
      // Set the state to the DDG URL immediately.
      // The <img> tag will attempt to load it. If it fails, its onError handler will trigger.
      if (iconUrl !== ddgUrl) { // Avoid unnecessary state update
        setIconUrl(ddgUrl);
      }
      // Cache the DDG URL optimistically. If it fails to load in the <img>,
      // the user experience is handled by onError, but we avoid re-checking cache next time.
      // We cache the *attempted* URL, not necessarily a *successful* one.
      try {
        localStorage.setItem(cacheKey, JSON.stringify({ url: ddgUrl, timestamp: Date.now() }));
      } catch (e) {
         console.error('Error writing favicon cache:', e);
         // Handle potential storage quota issues etc.
      }
    }
    // No cleanup function needed as we are not running async operations in the effect anymore

  }, [domain, iconUrl]); // Re-run effect if domain or iconUrl changes (to satisfy exhaustive-deps)

  // Return the current state (initial fallback, cached URL, or DDG URL)
  return iconUrl;
};

export default useFavicon;
