import React from 'react';
import styled from 'styled-components';

// Logo image styling
const LogoImage = styled.img`
  height: 32px; /* Adjust size as needed */
  width: auto;
  display: block; /* Remove extra space below image */
  /* Add other styles if necessary, e.g., margin */
`;

// The component now renders an image instead of text
const Logo = () => {
  // The 'title' prop is no longer needed
  return <LogoImage src="/assets/icon.png" alt="ffive logo" />;
};

export default Logo;
