import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  font-family: ${({ theme }) => theme.fontFamilies.mono};
  width: 100%;
  padding: ${({ theme }) => theme.space[3]};
  border: 2px solid rgba(var(--primary-color-rgb), 0.2);
  border-radius: ${({ theme }) => theme.radii.lg};
  background-color: rgba(var(--secondary-color-rgb), 0.8);
  color: var(--primary-color);
  outline: none;
  transition: all var(--animation-normal) ease;
  
  /* Remove default browser focus styles */
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  
  /* Base styling */
  font-size: ${({ theme }) => theme.fontSizes.md};
  letter-spacing: 0.01em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  
  /* Mobile optimization - larger text on mobile for better readability */
  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: ${({ theme }) => theme.fontSizes.md};
    padding: ${({ theme }) => theme.space[3]};
  }
  
  /* Focus state */
  &:focus {
    outline: none;
    border-color: var(--accent-color);
    box-shadow: 0 0 0 1px rgba(var(--accent-color-rgb), 0.2);
    background-color: var(--secondary-color);
  }
  
  /* Hover state */
  &:hover:not(:focus) {
    border-color: rgba(var(--primary-color-rgb), 0.3);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  }
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    box-shadow: none;
  }
  
  &::placeholder {
    color: rgba(var(--primary-color-rgb), 0.4);
    transition: opacity var(--animation-fast) ease;
  }
  
  &:focus::placeholder {
    opacity: 0.6;
  }
  
  /* Mobile optimization - better handling of autofill */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--primary-color);
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0 30px var(--secondary-color) inset;
  }
  
  /* Mobile optimization - prevent zoom on focus in iOS */
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 16px; /* Minimum font size to prevent zoom on iOS */
  }
`;

const Input = ({ ...props }) => {
  return (
    <StyledInput 
      {...props} 
      autoComplete="off"
      autoCorrect="off"
      spellCheck="false"
    />
  );
};

export default Input;
