import React from 'react';
import styled from 'styled-components';
// Import package.json to get version - Note: This might require build tool configuration (e.g., Webpack DefinePlugin or Vite env variables)
// For now, we'll use a placeholder. A better approach is needed for production.
// import packageInfo from '../../../package.json'; // This direct import might not work out-of-the-box

const MinimalFooterBar = styled.footer`
  height: 40px; /* Minimal height */
  background-color: transparent; /* Or match primary/secondary */
  display: flex;
  align-items: center;
  justify-content: center; /* Center content */
  padding: 0 ${({ theme }) => theme.space[3]};
  flex-shrink: 0;
  position: fixed; /* Fixed position at the bottom */
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndices.footer}; /* Ensure it's above content if needed, but below header/modals */
  transition: background-color var(--animation-normal) ease;
  color: rgba(var(--secondary-color-rgb), 0.6); /* Subdued text color */
  font-size: ${({ theme }) => theme.fontSizes.xs};

  ${({ theme }) => theme.mediaQueries.xs} {
    height: 35px;
    padding: 0 ${({ theme }) => theme.space[2]};
  }
`;

const FooterText = styled.p`
  margin: 0;
  text-align: center;
`;

// Placeholder for version - replace with a proper method later
const APP_VERSION = process.env.REACT_APP_VERSION || 'dev'; // Example using env var

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <MinimalFooterBar>
      <FooterText>
        © {currentYear} ffive.org | v{APP_VERSION}
      </FooterText>
    </MinimalFooterBar>
  );
};

export default Footer;
