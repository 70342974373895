import { useState, useEffect, useCallback } from 'react'; // Added useCallback
import { colorPalettes } from '../styles/theme'; // Removed unused ensureContrast

const useTheme = () => {
  const [selectedPalette, setSelectedPalette] = useState('random'); // User's choice ('random' or specific name)
  const [randomSelectedPalette, setRandomSelectedPalette] = useState(null); // Actual palette name if 'random' is chosen

  // Helper function to get a random palette name
  const getRandomPaletteName = useCallback(() => {
    const paletteNames = Object.keys(colorPalettes).filter(name => name !== 'random');
    if (paletteNames.length === 0) return 'black'; // Fallback if no palettes defined besides random
    return paletteNames[Math.floor(Math.random() * paletteNames.length)];
  }, []); // No dependencies, colorPalettes is static

  // Load saved palette from localStorage on initial render
  useEffect(() => {
    const savedChoice = localStorage.getItem('selectedPalette') || 'random';
    setSelectedPalette(savedChoice);

    if (savedChoice === 'random') {
      // Always generate a new random palette on page load when 'random' is selected
      const newRandomPalette = getRandomPaletteName();
      setRandomSelectedPalette(newRandomPalette);
      // We don't save the random palette to localStorage anymore
      // This ensures a new random palette is chosen on each page load
    } 
    // No 'else' needed here, setSelectedPalette(savedChoice) already handled it above
  }, [getRandomPaletteName]); // Add dependency

  // Apply color palette choice
  const applyColorPalette = useCallback((paletteChoice) => {
    localStorage.setItem('selectedPalette', paletteChoice); // Save user's choice
    setSelectedPalette(paletteChoice);

    if (paletteChoice === 'random') {
      const newRandomPalette = getRandomPaletteName();
      setRandomSelectedPalette(newRandomPalette);
      // Don't save the random palette to localStorage
      // This allows a new random palette to be chosen on each page load
    } else {
      setRandomSelectedPalette(null);
      localStorage.removeItem('randomSelectedPalette'); // Clean up old random choice
    }
  }, [getRandomPaletteName]); // Add dependency

  // Get current theme colors based on selection
  const getThemeColors = useCallback(() => {
    const paletteToUse = selectedPalette === 'random'
      ? randomSelectedPalette // This should be set by useEffect or applyColorPalette
      : selectedPalette;

    // Fallback to 'black' if the determined palette doesn't exist (shouldn't happen often)
    return colorPalettes[paletteToUse] || colorPalettes.black;
  }, [selectedPalette, randomSelectedPalette]);

  return {
    selectedPalette,
    applyColorPalette,
    getThemeColors,
  };
};

export default useTheme;
