import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { colorPalettes } from '../../styles/theme';
import PaletteOption from './PaletteOption';
import FloatingPopup from '../Layout/FloatingPopup';

const PaletteContainer = styled.div`
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  display: flex;
  flex-direction: column;
  align-items: center; /* Changed from flex-end to center */
  justify-content: center; /* Added to ensure vertical centering */
  height: 100%;
  user-select: none;
`;

const PaletteToggle = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: all var(--animation-fast) ease;
  position: relative;
  
  &:hover {
    background-color: rgba(var(--secondary-color-rgb), 0.1);
  }
  
  &:active {
    transform: scale(0.95);
  }
  
  ${({ $isOpen }) => $isOpen && `
    background-color: rgba(var(--secondary-color-rgb), 0.15);
  `}
  
  ${({ theme }) => theme.mediaQueries.lg} {
    width: 44px;
    height: 44px;
  }
`;

const PaletteEmoji = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  transition: transform var(--animation-fast) ease;
  
  ${PaletteToggle}:hover & {
    transform: scale(1.1);
  }
`;

const ColorPalette = ({ selectedPalette, onSelectPalette }) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef(null);
  
  const togglePalette = () => {
    setIsOpen(prev => !prev);
  };

  // Get all palette names
  const paletteNames = Object.keys(colorPalettes);
  
  // Add random option
  const allOptions = [...paletteNames, 'random'];

  return (
    <PaletteContainer>
      <PaletteToggle 
        ref={triggerRef} 
        onClick={togglePalette} 
        aria-label="Toggle color palette"
        aria-haspopup="true"
        aria-expanded={isOpen}
        $isOpen={isOpen}
      >
        <PaletteEmoji>🎨</PaletteEmoji>
      </PaletteToggle>
      
      <FloatingPopup
        isOpen={isOpen}
        onClose={togglePalette}
        triggerRef={triggerRef}
        alignment="right"
      >
        {allOptions.map((paletteName) => (
          <PaletteOption
            key={paletteName}
            paletteName={paletteName}
            isSelected={selectedPalette === paletteName}
            onClick={() => {
              onSelectPalette(paletteName);
              setIsOpen(false);
            }}
          />
        ))}
      </FloatingPopup>
    </PaletteContainer>
  );
};

export default ColorPalette;
