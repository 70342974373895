import React, { useEffect, useRef, useState, useMemo } from 'react';
import styled from 'styled-components';
import useFavicon from '../../hooks/useFavicon'; // Import the hook

// Helper to extract domain from URL (copied again for self-containment, consider utils file later)
const getDomainFromUrl = (url) => {
  try {
    const fullUrl = url.includes('://') ? url : `https://${url}`;
    const parsedUrl = new URL(fullUrl);
    return parsedUrl.hostname.replace(/^www\./, '');
  } catch (e) {
    return null;
  }
};


const SuggestionsContainer = styled.div`
  position: absolute;
  background-color: var(--secondary-color); /* Match FloatingPopup */
  color: var(--primary-color); /* Match FloatingPopup text */
  border: 1px solid rgba(var(--primary-color-rgb), 0.1); /* Match FloatingPopup */
  border-radius: ${({ theme }) => theme.radii.lg}; /* Match FloatingPopup */
  /* Match FloatingPopup shadow */
  box-shadow: 0 6px 16px -5px rgba(0, 0, 0, 0.1), 
              0 3px 6px -3px rgba(0, 0, 0, 0.05); 
  z-index: ${({ theme }) => theme.zIndices.dropdown}; /* Keep dropdown z-index */
  padding: ${({ theme }) => theme.space[1]}; /* Add small padding around items */
  min-width: 220px; /* Match FloatingPopup */
  max-height: 300px; /* Limit height */
  overflow-y: auto; /* Allow scrolling */
  width: ${({ $width }) => $width}px; /* Match input width */
  top: ${({ $top }) => $top}px; /* Position below input */
  left: ${({ $left }) => $left}px; /* Align with input */
  margin-top: ${({ theme }) => theme.space[2]}; /* Increase gap slightly like FloatingPopup */
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
  /* Use similar transform as FloatingPopup */
  transform: ${({ $show }) => ($show ? 'translateY(0)' : 'translateY(-10px)')}; 
  transition: opacity var(--animation-normal) ease, 
              transform var(--animation-normal) ease,
              visibility var(--animation-normal) ease;
  transform-origin: top center; /* Match FloatingPopup */

  /* Scrollbar styling from FloatingPopup */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(var(--primary-color-rgb), 0.05);
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(var(--primary-color-rgb), 0.2);
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--primary-color-rgb), 0.3);
  }
`;

// Updated SuggestionItem to include icon
const SuggestionItem = styled.button`
  display: flex; /* Use flexbox for icon + text */
  align-items: center; /* Vertically align icon and text */
  width: 100%;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  text-align: left;
  cursor: pointer;
  /* Match NavMenu item highlight/hover */
  background-color: ${({ $isHighlighted }) =>
    $isHighlighted ? 'rgba(var(--primary-color-rgb), 0.1)' : 'transparent'}; 
  color: var(--primary-color); /* Match text color inside popup */
  border: none;
  border-radius: ${({ theme }) => theme.radii.md}; /* Match NavMenu item */
  font-family: ${({ theme }) => theme.fontFamilies.mono};
  font-size: ${({ theme }) => theme.fontSizes.md};
  transition: background-color var(--animation-fast) ease;

  img {
    width: 16px;
    height: 16px;
    margin-right: ${({ theme }) => theme.space[3]};
    object-fit: contain;
    flex-shrink: 0;
  }

  &:hover {
     /* Match NavMenu item hover */
    background-color: rgba(var(--primary-color-rgb), 0.08);
  }
`;

// Component to render a single suggestion item with its icon
const SuggestionItemWithIcon = ({ suggestion, isHighlighted, onClick, id }) => {
  // Determine domain/icon source based on suggestion type
  const domainOrIconDomain = suggestion.type === 'url'
    ? suggestion.domain // Use pre-extracted domain for URL type
    : suggestion.type === 'search'
      ? suggestion.iconDomain // Use the specific domain for the search icon
      : getDomainFromUrl(suggestion.url); // Extract domain for site links

  const iconUrl = useFavicon(domainOrIconDomain);

  return (
    <SuggestionItem
      onClick={onClick}
      $isHighlighted={isHighlighted}
      role="option"
      aria-selected={isHighlighted}
      id={id}
    >
      <img src={iconUrl} alt="" onError={(e) => { // Basic fallback directly on image
        if (e.target.src !== '/assets/icons/favicon.ico') {
          e.target.onerror = null;
          e.target.src = '/assets/icons/favicon.ico';
        }
      }} />
      {/* Display URL for 'url', query string label for 'search', label for site links */}
      {/* Always display the label */}
      {suggestion.label}
    </SuggestionItem>
  );
};


const SearchSuggestions = ({
  siteSuggestions,
  detectedUrl,
  detectedDomain,
  defaultSearchSuggestion, // Add new prop
  showSuggestions,
  onSelectSuggestion,
  highlightedIndex,
  triggerRef,
  listId,
  itemPrefixId,
}) => {
  const containerRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });

  // Calculate position based on the trigger element
  useEffect(() => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setPosition({
        // Position below the input, accounting for scroll
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width, // Match the width of the input container
      });
    }
  }, [triggerRef, showSuggestions]);

  // Combine detected URL and site suggestions
  const combinedSuggestions = useMemo(() => {
    let combined = [];
    if (detectedUrl) {
      // Ensure URL starts with https:// for display and execution consistency
      const displayUrl = detectedUrl.startsWith('http') ? detectedUrl : `https://${detectedUrl}`;
      combined.push({
        type: 'url',
        label: detectedDomain, // Use pretty domain as label for display
        url: displayUrl,   // Use full URL for execution
        domain: detectedDomain, // Pass domain for favicon hook
      });
    }
    // Add site suggestions
    combined = combined.concat(siteSuggestions);
    // Add default search suggestion if it exists
    if (defaultSearchSuggestion) {
      combined.push(defaultSearchSuggestion);
    }
    return combined;
  }, [detectedUrl, detectedDomain, siteSuggestions, defaultSearchSuggestion]); // Add defaultSearchSuggestion dependency


  // Scroll highlighted item into view (logic remains similar)
  useEffect(() => {
    if (
      highlightedIndex >= 0 &&
      containerRef.current &&
      containerRef.current.children[highlightedIndex] &&
      // Add check for scrollIntoView existence (for JSDOM compatibility)
      typeof containerRef.current.children[highlightedIndex].scrollIntoView === 'function'
    ) {
      containerRef.current.children[highlightedIndex].scrollIntoView({
        behavior: 'smooth', // Keep smooth for browsers
        block: 'nearest',
      });
    }
    // Dependency array includes combinedSuggestions length in case it changes
  }, [highlightedIndex, combinedSuggestions.length]);

  // Determine visibility based on combined list
  const isVisible = showSuggestions && combinedSuggestions.length > 0;

  return (
    <SuggestionsContainer
      ref={containerRef}
      $show={isVisible}
      $top={position.top}
      $left={position.left}
      $width={position.width}
      role="listbox" // Accessibility
      id={listId}
      aria-hidden={!isVisible}
    >
      {combinedSuggestions.map((suggestion, index) => (
        <SuggestionItemWithIcon
          // Use a combination of type and url for a more robust key
          key={`${suggestion.type}-${suggestion.url}-${index}`}
          suggestion={suggestion}
          isHighlighted={index === highlightedIndex}
          onClick={() => onSelectSuggestion(suggestion)} // Pass the whole suggestion object
          id={`${itemPrefixId}-${index}`}
        />
      ))}
      {/* Optional: Show message if input has text but no suggestions match (logic might need adjustment) */}
      {/* {showSuggestions && combinedSuggestions.length === 0 && searchTerm.trim() !== '' && (
        <NoSuggestionsMessage>No matching sites found.</NoSuggestionsMessage>
      )} */}
    </SuggestionsContainer>
  );
};

export default SearchSuggestions;
