import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// --- Constants ---
const VIEWPORT_PADDING = 10; // Padding from viewport edges
const TRIGGER_SPACING = 8; // Space between trigger and popup

// --- Styled Components ---
const PopupContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color);
  border-radius: ${({ theme }) => theme.radii.lg};
  padding: ${({ theme }) => theme.space[4]};
  box-shadow: 0 6px 16px -5px rgba(0, 0, 0, 0.1), 
              0 3px 6px -3px rgba(0, 0, 0, 0.05);
  gap: ${({ theme }) => theme.space[2]};
  max-height: 70vh;
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndices.modal};
  min-width: 220px;
  color: var(--primary-color);
  border: 1px solid rgba(var(--primary-color-rgb), 0.1);
  
  /* Animation states */
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  transition: opacity var(--animation-normal) ease,
              visibility var(--animation-normal) ease,
              transform var(--animation-normal) ease;
  transform: ${({ $isOpen }) => ($isOpen ? 'translateY(0)' : 'translateY(-10px)')}; /* Animate down */
  transform-origin: top center; /* Origin for animation */

  /* Dynamic positioning styles */
  top: ${({ $styles }) => $styles.top || 'auto'};
  left: ${({ $styles }) => $styles.left || 'auto'};
  right: ${({ $styles }) => $styles.right || 'auto'};
  bottom: auto; /* Explicitly remove bottom positioning */

  /* Mobile optimization */
  -webkit-overflow-scrolling: touch;
  ${({ theme }) => theme.mediaQueries.xs} {
    max-width: calc(100vw - ${({ theme }) => theme.space[6]});
    min-width: 180px;
    padding: ${({ theme }) => theme.space[3]};
  }
  
  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: rgba(var(--primary-color-rgb), 0.05);
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgba(var(--primary-color-rgb), 0.2);
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--primary-color-rgb), 0.3);
  }
`;

// --- Component ---
const FloatingPopup = ({
  isOpen,
  onClose,
  triggerRef, // Ref of the element that triggers the popup
  alignment = 'left', // 'left', 'right', 'center'
  children,
}) => {
  const popupRef = useRef(null);
  const [calculatedStyles, setCalculatedStyles] = useState({});

  // --- Positioning Logic ---
  useEffect(() => {
    const calculatePosition = () => {
      if (!isOpen || !triggerRef?.current || !popupRef?.current) {
        return;
      }

      const triggerRect = triggerRef.current.getBoundingClientRect();
      const popupRect = popupRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // --- Vertical Positioning (Top) ---
      // Start by positioning below the trigger
      let top = triggerRect.bottom + TRIGGER_SPACING;

      // Check if it goes off the bottom of the screen
      if (top + popupRect.height > viewportHeight - VIEWPORT_PADDING) {
        // If it overflows bottom, try positioning it above the trigger
        top = triggerRect.top - popupRect.height - TRIGGER_SPACING;

        // If it *still* overflows (e.g., very tall popup), position at top of viewport
        if (top < VIEWPORT_PADDING) {
          top = VIEWPORT_PADDING;
          // Optional: Add max-height adjustment here if needed
          // popupRef.current.style.maxHeight = `${viewportHeight - VIEWPORT_PADDING * 2}px`;
        }
      }
      // Ensure it doesn't go above the top padding
      if (top < VIEWPORT_PADDING) {
          top = VIEWPORT_PADDING;
      }


      // --- Horizontal Positioning (Left/Right) ---
      let left = 'auto';
      let right = 'auto';

      if (alignment === 'left') {
        left = triggerRect.left;
        // Boundary check - if popup would go off right screen edge
        if (left + popupRect.width > viewportWidth - VIEWPORT_PADDING) {
          // Adjust to align right edge with viewport padding
          left = 'auto';
          right = VIEWPORT_PADDING;
        }
        // Ensure left doesn't go past left edge
        if (left < VIEWPORT_PADDING) {
            left = VIEWPORT_PADDING;
        }
      } else if (alignment === 'right') {
        right = viewportWidth - triggerRect.right;
         // Boundary check - if popup would go off left screen edge
        if (triggerRect.right - popupRect.width < VIEWPORT_PADDING) {
           // Adjust to align left edge with viewport padding
          left = VIEWPORT_PADDING;
          right = 'auto';
        }
         // Ensure right doesn't go past right edge (handled by initial calc)
      } else if (alignment === 'center') {
        const triggerCenter = triggerRect.left + triggerRect.width / 2;
        left = triggerCenter - popupRect.width / 2;

        // Boundary check - right edge
        if (left + popupRect.width > viewportWidth - VIEWPORT_PADDING) {
          left = 'auto';
          right = VIEWPORT_PADDING;
        }
        // Boundary check - left edge
        if (left < VIEWPORT_PADDING) {
          left = VIEWPORT_PADDING;
          right = 'auto';
        }
      }

      // Use 'px' units
      const finalTop = `${top}px`;
      const finalLeft = typeof left === 'number' ? `${left}px` : left;
      const finalRight = typeof right === 'number' ? `${right}px` : right;

      setCalculatedStyles({ top: finalTop, left: finalLeft, right: finalRight });
    };

    // Calculate initially and on resize
    calculatePosition(); 
    window.addEventListener('resize', calculatePosition);

    // Recalculate after a short delay to ensure popup is rendered
    let timeoutId;
    if (isOpen) {
      timeoutId = setTimeout(calculatePosition, 50);
    }

    return () => {
      window.removeEventListener('resize', calculatePosition);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isOpen, triggerRef, alignment]);

  // --- Click Outside Logic ---
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        triggerRef?.current &&
        !triggerRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isOpen, onClose, triggerRef]);

  // --- Keyboard Navigation ---
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isOpen && event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  return (
    <PopupContainer 
      ref={popupRef} 
      $isOpen={isOpen} 
      $styles={calculatedStyles}
      role="dialog"
      aria-modal="true"
    >
      {children}
    </PopupContainer>
  );
};

export default FloatingPopup;
