import React, { forwardRef } from 'react'; // Removed useState, useEffect. Added forwardRef
import styled from 'styled-components';
import Input from './Input';

const SearchForm = styled.form`
  width: 100%; /* Take full width of its container */
  max-width: 600px; /* Set a max-width for larger screens */
  position: relative;
  /* flex-grow: 1; Removed */
  display: flex;
  align-items: center;
  transition: all var(--animation-normal) ease;
  margin: 0 auto; /* Center the form horizontally */
  padding: 0 ${({ theme }) => theme.space[3]}; /* Add horizontal padding */

  /* Adjust padding for smaller screens if needed */
  ${({ theme }) => theme.mediaQueries.xs} {
    padding: 0 ${({ theme }) => theme.space[2]};
  }

  /* No specific md query needed now */
  /* ${({ theme }) => theme.mediaQueries.md} {
    padding: 0; */
  }
`;

// Using the standard Input without extra padding
const StyledInput = styled(Input)``; // No changes needed here, props are passed down

const ClearButton = styled.button`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--primary-color);
  opacity: 0.4;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  display: ${({ $visible }) => ($visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  transition: opacity var(--animation-fast) ease;
  
  &:hover {
    opacity: 0.8;
    background-color: rgba(var(--primary-color-rgb), 0.05);
  }
  
  &:active {
    transform: translateY(-50%) scale(0.95);
  }
`;

// Search snippets for random placeholders - kept the same
const searchSnippets = [
  // Original Reading Rainbow snippets
  "Butterfly in the sky",
  "I can go twice as high",
  "I can go anywhere", 
  "Friends to know and ways to grow",
  "I can be anything",
  "Take a look its in a book",
  
  // New options
  "Find the music in the silence",
  "Paint with all the colors of the wind",
  "Unlock doors with curious keys",
  "Follow the echoes of starlight",
  "Plant a seed and watch it dream",
  "See the patterns hidden in plain sight",
  "Ride the currents of the air",
  "Listen to the stories stones tell",
  "Build bridges made of light",
  "Find the map within your heart",
  "Taste the sparkle of the dawn",
  "Weave threads of possibility",
  "Dance with your own shadow",
  "Hear the secrets rivers carry",
  "Climb mountains made of maybe",
  "Discover worlds inside a raindrop",
  "Let your imagination take flight",
  "Gather whispers from the leaves",
  "Find constellations in your teacup",
  "Grow roots deep and reach high",
  "Decode the language of the birds",
  "Find magic in the mundane",
  "Chase the horizon's gentle curve",
  "See the glow of inner knowing",
  "Ask the moon your quiet questions"
];

// Use forwardRef to accept the ref from App.jsx
const SearchInput = forwardRef((
  {
    value, // Controlled value from App.jsx
    onChange, // onChange handler from App.jsx
    onSearch, // Form submission handler from App.jsx
    onKeyDown, // Keydown handler for suggestions nav from App.jsx
    onFocus, // Focus handler from App.jsx
    placeholder,
    // Accessibility props passed down from App.jsx
    'aria-haspopup': ariaHasPopup,
    'aria-expanded': ariaExpanded,
    'aria-controls': ariaControls,
    'aria-activedescendant': ariaActiveDescendant,
    ...props // Spread remaining props
  },
  ref // The forwarded ref
) => {
  // Placeholder logic remains, but doesn't need state itself
  const randomPlaceholder = searchSnippets[Math.floor(Math.random() * searchSnippets.length)];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSearch) {
      onSearch(value.trim()); // Use the controlled value prop
    }
  };

  const handleClear = () => {
    // Call the onChange handler passed from App.jsx to clear the state there
    if (onChange) {
      // Simulate an event object to clear the value in App's state
      onChange({ target: { value: '' } });
    }
    // Focus the input after clearing - find the actual input element
    if (ref && ref.current) {
      const inputElement = ref.current.querySelector('input[name="searchInput"]');
      if (inputElement) {
        inputElement.focus();
      }
    }
  };

  return (
    // Attach the forwarded ref to the SearchForm container
    <SearchForm ref={ref} onSubmit={handleSubmit}>
      <StyledInput
        name="searchInput" // Keep name for potential focus targeting
        placeholder={placeholder || randomPlaceholder}
        value={value} // Use controlled value
        onChange={onChange} // Use controlled onChange
        onKeyDown={onKeyDown} // Pass through keydown handler
        onFocus={onFocus} // Pass through focus handler
        aria-label="Search" // Keep base aria-label
        // Pass down accessibility props
        aria-haspopup={ariaHasPopup}
        aria-expanded={ariaExpanded}
        aria-controls={ariaControls}
        aria-activedescendant={ariaActiveDescendant}
        role="combobox" // Add role for accessibility
        autoComplete="off" // Disable browser autocomplete
        {...props} // Spread other props
      />
      <ClearButton
        type="button"
        onClick={handleClear}
        $visible={value.length > 0} // Use controlled value for visibility
        aria-label="Clear search"
        tabIndex={-1} // Prevent tab focus on clear button
      >
        <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </ClearButton>
    </SearchForm>
  );
});

export default SearchInput;
