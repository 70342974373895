// Site configuration for ffive.org
export const siteConfig = {
  title: 'ffive.org', // Consolidated site title
  domain: 'ffive.org', // Consolidated site domain
  requiresAuth: true, // Retaining 'private' setting as requested initially
  navLinks: [
    { label: 'AUTH', url: 'https://auth.ffive.org', external: true, navType: 'auth' },
    { label: 'CYBERCHEF', url: 'https://cyber.ffive.org', external: true }, // Normal
    { label: 'FAST', url: 'https://fast.ffive.org', external: true, navType: 'auth' },
    { label: 'GIT', url: 'https://git.ffive.org', external: true }, // Normal
    { label: 'NPM', url: 'https://npm.ffive.org', external: true, navType: 'auth' },
    { label: 'PASTE', url: 'https://paste.ffive.org', external: true }, // Normal
    { label: 'SCRIBBLE', url: 'https://scribble.ffive.org', external: true }, // Normal
    { label: 'SEARCH', url: 'https://search.ffive.org', external: true, navType: 'newTab' },
    { label: 'TOOLS', url: 'https://tools.ffive.org', external: true }, // Normal
    { label: 'TRANSLATE', url: 'https://translate.ffive.org', external: true } // Normal
  ]
};

// No more variant detection or multiple configs needed
